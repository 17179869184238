import { Box, Input, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, ButtonBase, Card, Grid, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Mpesa from "../../../common/images/mpesa-icon.svg";
import CardImg from '../../../common/images/card-icon.svg'
// import { MpesaStk } from "../Modals/Invoice/mpesaStk";
import { PaymentSent } from "../../Modals/Invoice/paymentSent";
import HttpComponent from "../../School/MakeRequest";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import SendIcon from '@mui/icons-material/Send';
import BankPayBillIcon from "../../School/Images/bank-paybill.svg";
import SelectInput from "@mui/material/Select/SelectInput";
import CARDREADY from '../../School/Images/cardlinkready.svg';


const baseUrl = process.env.REACT_APP_BASE_URL;
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES", }).format(value);

export default function ParentPayMakePayment(props) {
    const navigate = useNavigate()
    const [value, setValue] = useState()
    const [successOpen, setSuccessOpen] = useState(false);
    const [stkPushLoading, setStkPushLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    const [mpesaPaymentOpen, setMpesaPaymentOpen] = useState(false);

    const [activeButton, setActiveButton] = useState('')
    const [userPhone, setUserPhone] = useState('')

    const [initialBalance, setInitialBalance] = useState('')

    const [invoicePayments, setInvoicePayments] = useState();
    const [invoiceBalance, setInvoiceBalance] = useState();
    const [amount, setAmount] = useState("")


    const [bankAccount, setBankAccount] = useState('')
    const [kcbphone, setkcbphone] = useState('')
    const [kcbAmount, setKcbAmount] = useState('')

    const [email, setEmailAddress] = useState('')
    const [cardAmount, setCardAmout] = useState('')

    const [invoiceData, setInvoiceData] = useState()
    const [errorFetching, setErrorFetching] = useState(false);
    const { invoiceNumber, darajaConfigId } = useParams();

    // Success Notification
    const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
    const handleSuccessNotificationClick = () => { setSuccessNotificationOpen(true); };
    const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };

    const [successNotificationOpen1, setSuccessNotificationOpen1] = React.useState(false);
    const handleSuccessNotificationClick1 = () => { setSuccessNotificationOpen1(true); };
    const handleSuccessNotificationClose1 = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen1(false); };

    // Success Notification
    const [successNotificationReceivedPaymentOpen, setSuccessNotificationReceivedPaymentOpen] = React.useState(false);
    const handleSuccessNotificationPaymentReceivedClick = () => { setSuccessNotificationReceivedPaymentOpen(true); };
    const handleSuccessNotificationReceivedPaymentClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationReceivedPaymentOpen(false); };

    const [bussinessStkId, setBussinessStkId] = useState('')

    // Error Notification
    const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);
    const handleErrorNotificationClick = () => { setErrorNotificationOpen(true); };
    const handleErrorNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationOpen(false); };
    // Card Error
    const [errorNotificationOpen1, setErrorNotificationOpen1] = React.useState(false);
    const handleErrorNotificationClick1 = () => { setErrorNotificationOpen1(true); };
    const handleErrorNotificationClose1 = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationOpen1(false); };

    // Error Notification
    const [errorNotificationTimeoutOpen, setErrorNotificationTimeoutOpen] = React.useState(false);
    const handleErrorNotificationTimeoutClick = () => { setErrorNotificationTimeoutOpen(true); };
    const handleErrorNotificationTimeoutClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationTimeoutOpen(false); };

    // Error Notification
    const [errorNotificationNoBusinessConfigOpen, setErrorNotificationNoBusinessConfigOpen] = React.useState(false);
    const handleErrorNotificationNoBusinessConfigClick = () => { setErrorNotificationNoBusinessConfigOpen(true); };
    const handleErrorNotificationNoBusinessConfigClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationNoBusinessConfigOpen(false); };

    const [successNotificationOpen2, setSuccessNotificationOpen2] = React.useState(false);
    const handleSuccessNotificationClick2 = () => { setSuccessNotificationOpen2(true); };
    const handleSuccessNotificationClose2 = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };


    const [mpesaLoading, setMpesaLoading] = useState(false)
    const [kcbloading, setkcbloading] = useState(false)
    const [Cardloading, setCardloading] = useState(false)

    const [intervalId, setIntervalId] = useState(null);
    const { X_Authorization } = useSelector((store) => store.user)


    const [successmessage, SetSuccessMessageCard] = useState(false)
    const [StkMessage, setSTKMessage] = useState(false)

    const [SuccessShow, setSuccessShow] = useState({ state: false, message: "" })
    const [GenerteLink, setGeneratedLink] = useState(false)
    const [GenerateLinkStatus, setGenerateLinkStatus] = useState(false)
    const [dataLINK, setDatagenerated] = useState('')
    



    console.log("GenerteLink keli checking", GenerteLink);
    const intervalIdRef = useRef(null);

    const textRef = useRef(null);

  const handleCopy = () => {
    textRef.current.select();
    document.execCommand('copy');
    // alert('LINK COPIED')
    handleSuccessNotificationClick2()
  };
   

    const handleClick = async (orderID) => {
        let object = { amount: amount || invoiceBalance, phone: userPhone, orderID: orderID };
        console.log('object', object);
        //TODO: Currently hard coded to do stk push for sweeton
        setMpesaLoading(true);
        let businessID = bussinessStkId || "63e2231b620df9bb8076c525"
        if (object.phone == '') {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the phone number' })
            console.error("Please provide the phone number");
            handleErrorNotificationClick();
        } else if (object.amount == '' || object.amount == 0) {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the amount ' })
            handleErrorNotificationClick();
        } else {
            try {
                HttpComponent({
                    method: "POST",
                    url: "/api/pushstk",
                    token: X_Authorization,
                    body: {
                        amount: object.amount,
                        phone: object.phone,
                        orderID: object.orderID
                    }

                })
                    .then((data) => {
                        if (data.response.data.status === 200) {
                            // handleSuccessNotificationClick();
                            listenWebsocket(invoiceNumber)
                        } else {
                            setMpesaLoading(false);
                            console.error("Problem Processing StK Payment");
                            handleErrorNotificationClick();
                        }
                    })
                    .catch((e) => {
                        setMpesaLoading(false);
                        console.error(`Error Sending STK`, e.message);
                    });
            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();
            }

        }





    };
    const handleKcbPush = async (orderID) => {
        let object = { amount: kcbAmount || invoiceBalance, phone: kcbphone, orderID: orderID };
        console.log('object', kcbAmount)

        if (kcbphone == '') {
            // alert('kindly add a phone number')
            setErrorShow({ state: true, message: 'kindly add a phone number' })
            setkcbloading(false)
        } else if (bankAccount == '') {
            setErrorShow({ state: true, message: 'kindly select  a bank account' })

            setkcbloading(false)
        } else if (invoiceBalance == 0 || object.amount == '' || object.amount == 0) {
            setErrorShow({ state: true, message: 'kindly add  a amount' })
            setkcbloading(false)
        } else {
            setkcbloading(true)
            try {
                HttpComponent({
                    method: 'POST',
                    url: "/api/v1/payments/initiate_kcb_stk_push",
                    body: {
                        amount: kcbAmount || invoiceBalance,
                        phone: kcbphone,
                        orderID: orderID
                    }
                }).then((data) => {
                    if (data.status == 200) {
                        handleSuccessNotificationClick();
                    } else {
                        setkcbloading(false)
                        console.error("Problem Processing StK Payment");
                        handleErrorNotificationClick();

                    }

                })
                    .catch((e) => {
                        setkcbloading(false)
                        console.error(`Error Sending STK`, e.message);

                    })

            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();

            }

        }

    }

    const handleCardPayment = async (orderID) => {
        const obj = { email: email, amount: cardAmount || invoiceBalance, orderID: orderID }
        console.log('Email Address', email, cardAmount, orderID)
        if (obj.cardAmount == '' || obj.cardAmount == 0) {
            setErrorShow({ state: true, message: 'kindly add  a amount' })


        } else {
            try {
                HttpComponent({
                    method: 'POST',
                    url: "/api/v1/payments/card/pay",
                    body: {
                        amount: cardAmount || invoiceBalance,
                        email: email,
                        orderID: orderID,
                    }
                }).then((data) => {
                    // console.log(data.response.data.data.data.invoiceInformation.paymentLink, 'Data');
                    console.log(data.response.data, 'Response data hapa');

                    if (data.status == 200) {

                        if (data.response.data.status === 500) {
                            console.log(data.response.data.error.message, 'Response data hapa');
                            console.log(' mesage arrr', data.response.data.error.message)
                            setErrorShow({ state: true, message: data.response.data.error.message })

                        } else if (data.response.data.status === 200) {
                            console.log("link data =>>>>>>>>>>>>", data.response.data.data);

                            handleSuccessNotificationClick1();
                            setGeneratedLink(true)
                            setGenerateLinkStatus(true)
                            setCardloading(true)

                            setDatagenerated(data.response.data.data.invoiceInformation.paymentLink)

                        }


                    } else if (data.status == '401') {
                        console.log(' mesage arrr', data.response.message)
                        setErrorShow({ state: true, message: data.response.message })
                    } else if (data.status == '400') {
                        console.log(' mesage arrr', data.response.message)
                        setErrorShow({ state: true, message: data.response.error })



                    } else {
                        handleErrorNotificationClick1();

                    }

                })
                    .catch((e) => {
                        setkcbloading(false)
                        console.error(`Error Sending STK`, e.message);

                    })

            } catch (error) {
                console.error(error);
                handleErrorNotificationClick1();

            }

        }




    }


    const getInvoiceDetails = async () => {
        try {
            const invoiceDetailsResponse = await fetch(baseUrl + `/api/get_invoice_by_invoice_number?invoiceNumber=${invoiceNumber}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                }
            );

            if (invoiceDetailsResponse.status === 200) {
                const invoiceDetails = await invoiceDetailsResponse.json();
                setInvoiceData(invoiceDetails.data);
                setInitialBalance(invoiceDetails.data.invoiceBalance)
                console.log('invoiceDetails.data', invoiceDetails.data);
                if(invoiceDetails.data?.paymentLink !== '' && invoiceDetails.data.invoiceStatus === 'Unpaid' ){
                    console.log(invoiceDetails.data.paymentLink,'invoiceDetails.data.paymentLink');
                    setGenerateLinkStatus(true)
                    setDatagenerated(invoiceDetails.data.paymentLink)
                
                }
            } else {
                setErrorFetching(true)
            }
        } catch (error) {
            console.error("error fetching invoice details", error.message);
        }
    };
    console.log(dataLINK, 'keli output' );

    useEffect(() => {
        if(dataLINK === undefined){
            console.log("hakuna data");
            setGeneratedLink(false)
        }

        if(dataLINK){
            setGeneratedLink(true)
        }

        // if((dataLINK !=='NO URL')){
        //     setGeneratedLink(true)
        //     console.log(GenerteLink, 'Generate link status');
    
        // }else{
            
        //     console.log(GenerteLink, 'Generate link status');

        // }
    }, [dataLINK])

    
    


    const getInvoicePayments = async () => {
        const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${invoiceNumber}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    'X-Authorization': localStorage.getItem('X-Authorization')
                },
            }
        );
        if (invoicePaymentDetailsResponse.status === 200) {
            const paymentDetailsData = await invoicePaymentDetailsResponse.json();
            let { invoicePayments, invoiceBalance } = paymentDetailsData.data
            setInvoicePayments(invoicePayments);
            setInvoiceBalance(invoiceBalance);
        }
    }

    const [called, setCalled] = useState(false);

    const getInvoiceTwo = async () => {
        const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${invoiceNumber}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    'X-Authorization': localStorage.getItem('X-Authorization')
                },
            }
        );
        if (invoicePaymentDetailsResponse.status === 200) {
            const paymentDetailsData = await invoicePaymentDetailsResponse.json();
            let { invoiceBalance } = paymentDetailsData.data
            setInitialBalance(invoiceBalance);
            setCalled(true)
        }
    }

    // const getTheInvoiceIfGenerated = async () => {
    //     try {
    //         const resp = await fetch(`https://cards.zed.business/api/v1/card-payment/invoice/KENYATTAHigh_6`, 
    //         { method:"GET",  headers: {
    //             "Content-Type": "application/json",
    //             Accept: "application/json",
    //         }}).then((data) => {
    //             console.log(data, 'RRRR')
    //         })
    //     } catch (error) {

    //     }
    // }

    // useEffect(() => {
    //     getTheInvoiceIfGenerated()

    // }, [invoiceNumber])


    useEffect(() => {
        if (!called) {
            getInvoiceTwo()
        }
    }, []);


    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    function handleButtonClick(name) {
        setActiveButton(name)
    }

    useEffect(() => {
        getInvoiceDetails().then(() => console.log("done fetching invoice data"))
        getInvoicePayments().then(() => console.log("payments fetched"))
        setBussinessStkId(darajaConfigId)
    }, []);

    useEffect(() => {
        handleButtonClick(activeButton)
    }, [activeButton])

    const [counter, setCounter] = useState(65);

    useEffect(() => {
        let intervalId;

        if (mpesaLoading || kcbloading) {
            setCounter(65)
            intervalId = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);

            setTimeout(() => {
                clearInterval(intervalId);
                setMpesaLoading(false);
                setkcbloading(false)
                setCardloading(false)
                handleErrorNotificationTimeoutClick();
            }, 65000);
        }


        return () => clearInterval(intervalId);
    }, [mpesaLoading, kcbloading]);

    useEffect(() => {
        if (Cardloading) {
            let intervalId;

            setCounter(15)
            intervalId = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 3000);

            setTimeout(() => {

                setCardloading(false)
            }, 3000);
        }

    }, [Cardloading])

    useEffect(() => {
        console.log(initialBalance, 'initiaL BALANCE')
        console.log(invoiceBalance, 'INVOICE BALANCE')
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }
        if (mpesaLoading || kcbloading) {
            intervalIdRef.current =
                setInterval(() => {
                    getInvoicePayments();
                    if (initialBalance && (parseInt(initialBalance) !== parseInt(invoiceBalance))) {
                        setInvoiceBalance(initialBalance)
                        setMpesaLoading(false);
                        setkcbloading(false)
                        handleSuccessNotificationPaymentReceivedClick()
                        if(invoiceNumber){
                            navigate(`/invoice/${invoiceNumber}`)
                        }
                       if(darajaConfigId){
                        navigate(`/view/invoice/${invoiceNumber}/${darajaConfigId}`)
                       }
                        clearInterval(intervalIdRef.current);
                        intervalIdRef.current = null;
                        setUserPhone('')
                        setAmount('')
                        console.log(initialBalance, 'initiaL BALANCE')
                        console.log(invoiceBalance, 'INVOICE BALANCE')
                        // console.log(invoicePayments[invoicePayments.length - 1].receiptNumber)
                        //navigate(`/receipt/${invoicePayments[invoicePayments.length - 1].receiptNumber}/${invoiceNumber}`)
                    }
                }, 1000)
            setTimeout(() => {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
                handleErrorNotificationTimeoutClick()
                setMpesaLoading(false);
                setkcbloading(false)
            }, 65000);
        }
        return () => {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        };
    }, [mpesaLoading, kcbloading, initialBalance, invoiceBalance]);

    useEffect(() => {
        if (successmessage) {
            setTimeout(() => {
                SetSuccessMessageCard(false)

            }, 1000)

        }

    }, [successmessage])
    const listenWebsocket = async(invoiceNumber) => {
        let ws = ""
        if(process.env.NODE_ENV === 'development'){
             ws = new WebSocket(`wss://api.dev.zed.business/notification/${invoiceNumber}`);
        }else if(process.env.NODE_ENV === 'production'){
             ws = new WebSocket(`wss://portal.zed.business/notification/${invoiceNumber}`);
        }else if(process.env.NODE_ENV === 'test'){
          //zed.swerri.io
           ws = new WebSocket(`wss://zed.swerri.io/notification/${invoiceNumber}`);
        }
        console.log(ws, 'ws');
       
        // const ws = new WebSocket(`wss://dev.zed.business/notification/${invoiceNumber}}`);
        ws.onopen = () => {
            console.log('connected')
        }
        ws.onmessage = function(event) {
          const data = JSON.parse(event.data); // Parse the JSON string into an object
      
          console.log('Received message:', data);
          if(data.status === "OK"){
            const message = data.message;
            setSTKMessage (message)
           console.log(message, 'message')
           handleSuccessNotificationClick();
      
          }else if(data.status === "FAILED"){
            setSuccessNotificationOpen(false)
            const message = data.statusMessage;
            setSTKMessage (message)
           console.log(message, 'message')
           setErrorShow({ state: true, message: message });
           setMpesaLoading(false);
      
      
          }
         
          // Handle incoming messages from the WebSocket server here.
        };
        ws.onclose = function(event) {
          console.log('WebSocket connection closed with code:', event.code);
          // Handle WebSocket connection close event here.
        };
      
        ws.onerror = function(error) {
          console.error('WebSocket error:', error);
          // Handle any WebSocket errors here.
        };
        
      }
      


    return (
        <div>

            {/*Alerts*/}

            {/*Success Creation*/}
            <SuccessAlert message={'You will receive a prompt on your phone'} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
            <SuccessAlert message={'We have sent you an email kindly complete the transcation'} open={successNotificationOpen1} onClose={handleSuccessNotificationClose1} horizontal={'right'} vertical={'top'} />
            <SuccessAlert message={'Link copied successfully'} open={successNotificationOpen2} onClose={handleSuccessNotificationClose2} horizontal={'right'} vertical={'top'} />


            {/*Payment Received*/}
            <SuccessAlert message={`Payment received`} open={successNotificationReceivedPaymentOpen} onClose={handleSuccessNotificationReceivedPaymentClose} horizontal={'right'} vertical={'top'} />

            {/*error sending stk*/}
            <ErrorAlert message={`Error With Stk`} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={'right'} vertical={'top'} />

            <ErrorAlert message={`Error With Sending the payment`} open={errorNotificationOpen1} onClose={handleErrorNotificationClose1} horizontal={'right'} vertical={'top'} />


            {/*error TimeOut*/}
            <ErrorAlert message={StkMessage ? `${StkMessage}` : `Timeout On Awaiting Payment `}open={errorNotificationTimeoutOpen} onClose={handleErrorNotificationTimeoutClose} horizontal={'right'} vertical={'top'} />

            {/*Missing Business Config ID*/}
            <ErrorAlert message={`Missing Mpesa STK Config Id!`} open={errorNotificationNoBusinessConfigOpen} onClose={handleErrorNotificationNoBusinessConfigClose} horizontal={'right'} vertical={'top'} />

            <ErrorAlert
                vertical="top"
                horizontal="right"
                onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state}
                message={errorShow.message} />

            {/*mainContainer*/}
            <Grid container direction={'row'} justifyContent={'space-between'} sx={{ marginLeft: "5%" }}>

                {/*Labels*/}
                <Grid style={{ width: '30%' }} item>

                    {/*Label*/}
                    <Grid container mb={7}>
                        <Grid item>
                            <span style={{ color: '#032541', fontSize: '20px', fontWeight: '600' }} >Select Payment Method</span>
                        </Grid>
                    </Grid>

                    {/*Cards*/}
                    <Grid container direction={'column'} justifyContent={'flex-start'} spacing={7}>

                        {/*Mpesa Card*/}
                        <Grid item>
                            <ButtonBase onClick={() => { handleButtonClick('mpesa') }}>
                                <Card style={{ background: `${activeButton === 'mpesa' ? 'rgba(23, 174, 123, 0.06)' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                    <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

                                        {/*mpesa image*/}
                                        <Grid item>
                                            <img src={Mpesa} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLeft: '15px' }} />
                                        </Grid>

                                        {/*Mpesa name*/}
                                        <Grid item style={{ marginTop: "30px" }}>
                                            <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Mpesa</span>
                                        </Grid>

                                    </Grid>
                                </Card>
                            </ButtonBase>
                        </Grid>
                        {/* bank paybill */}
                        <Grid item>
                            <ButtonBase onClick={() => { handleButtonClick('bankpaybill') }}>
                                <Card style={{ background: `${activeButton === 'bankpaybill' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                    <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

                                        {/*Card image*/}
                                        <Grid item>
                                            <img src={BankPayBillIcon} alt="BankPayBillIcon" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                        </Grid>

                                        {/*Card name*/}
                                        <Grid item style={{ marginTop: "30px" }}>
                                            <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Bank Paybill</span>
                                        </Grid>

                                    </Grid>
                                </Card>
                            </ButtonBase>
                        </Grid>

                        {/*Card*/}
                        <Grid item>
                            <ButtonBase onClick={() => { handleButtonClick('card') }}>
                                <Card style={{ background: `${activeButton === 'card' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                    <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

                                        {/*Card image*/}
                                        <Grid item>
                                            <img src={CardImg} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                        </Grid>

                                        {/*Card name*/}
                                        <Grid item style={{ marginTop: "30px" }}>
                                            <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Card</span>
                                        </Grid>

                                    </Grid>
                                </Card>
                            </ButtonBase>
                        </Grid>



                    </Grid>

                </Grid>

                {/*Display Arena*/}
                {activeButton === 'mpesa' ?
                    <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                        <Grid container direction={'column'} ml={1} spacing={3}>

                            {/*Header lable*/}
                            <Grid item mt={5} >
                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice-Mpesa</span>
                            </Grid>

                            {/*Invoice card Information*/}
                            <Grid item>

                                <Grid container direction={'row'} spacing={2}>
                                    <Grid item style={{ width: '70%' }}>
                                        {/*PlyCard*/}
                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                            <Grid item>
                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                            </Grid>
                                            <Grid item>
                                                <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item style={{ width: '30%' }}>
                                        {/*Amount*/}
                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                            <Grid item>
                                                <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                            </Grid>
                                            <Grid item>
                                                <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(invoiceBalance)}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>

                            {/*Mpesa Instruction*/}
                            <Grid item mt={-2}>
                                <span style={{ fontSize: '14px' }} >Kindly enter your mobile number to receive the payment prompt and enter you M-Pesa Pin.</span>
                            </Grid>

                            {/*Mobile input*/}
                            <Grid item>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                </div>

                                <PhoneInput required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)} />
                            </Grid>

                            {/*Amount Input*/}
                            <Grid item>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                </div>
                                <TextField defaultValue={invoiceBalance} onChange={(e) => { setAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                            </Grid>

                            {/*Send prompt button*/}
                            <Grid item mt={10} mb={9}>
                                <Grid container justifyContent={'center'} alignContent={'center'}>
                                    <Grid item>
                                        {mpesaLoading ?
                                            <>
                                                <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                    endIcon={<SendIcon />}
                                                    loading={true}
                                                    loadingPosition="end"
                                                    variant="contained">
                                                    <span>Awaiting Payment</span>
                                                </LoadingButton>
                                                <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                    <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                        Resend Prompt: {counter}
                                                    </Typography>
                                                </Box>
                                            </>

                                            :
                                            <>
                                                <Button onClick={() => { handleClick(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Pay Now</Button>
                                            </>
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid> : activeButton === 'card' ?

                        <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                            <Grid container direction={'column'} ml={1} spacing={3}>
                                {GenerteLink ? <>
                                {/* {console.log('GenerteLink inside div',GenerteLink)} */}
                                <div className="pt-5 mt-5">
                                    <div className="div-card-ready" style={{display:"flex", justifyContent:"center", alignItems:"center",flexDirection:"column"}}>
                                        <img src={CARDREADY} alt="" />

                                        <div className="pt-3">
                                            <h5 style={{fontSize:"20px", color:"#032541", textAlign:"center", fontWeight:"bold"}}>Your Payment Link is Ready</h5>
                                            <h4  style={{fontSize:"14px", color:"#707070", textAlign:"center"}}>Open or Copy your payment link for </h4>
                                            <h4  style={{fontSize:"14px", color:"#032541", textAlign:"center"}}>Invoice NO: {invoiceNumber}</h4>
                                            <h4  style={{fontSize:"16px", color:"#dc3545", textAlign:"center"}}>Balance  {numberFormat(invoiceBalance)} </h4>
                                            <input style={{display:"", opacity:"0"}} ref={textRef}  type="text" value={dataLINK}  readOnly  />

                                            <div className="button-group" style={{display:"flex", justifyContent:"center", alignContent:"center"}}>
                                                
                                                <input ref={textRef}  type="text" value={text} readOnly />
                                                <Button style={{height:"40px", marginTop:"19px", padding:"0px 20px", fontSize:"16px", fontWeight:"500", color:"#032541", border:"2px solid #032541",}} onClick={handleCopy} >Copy Link</Button>
                                                <a href={`${dataLINK}`}>
                                                <Button style={{height:"40px", marginTop:"19px", padding:"0px 20px", fontSize:"16px", fontWeight:"500", color:"#fff",backgroundColor:"#032541",  border:"2px solid #032541"}} className="ml-3" onClick={() => {
                                                    window.href(`${dataLINK}`)
                                                }} >Open LInk</Button>

                                                </a>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                </> : <>
                                <>
                                    {/*Header lable*/}
                                    <Grid item mt={5} >
                                        <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice-Card</span>
                                    </Grid>

                                    {/*Invoice card Information*/}
                                    <Grid item>

                                        <Grid container direction={'row'} spacing={2}>
                                            <Grid item style={{ width: '70%' }}>
                                                {/*PlyCard*/}
                                                <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                    <Grid item>
                                                        <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item style={{ width: '30%' }}>
                                                {/*Amount*/}
                                                <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                    <Grid item>
                                                        <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                    </Grid>
                                                    <Grid item>
                                                        <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(invoiceBalance)}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                    {/*Mpesa Instruction*/}
                                    <Grid item mt={-2}>
                                        {/* <span style={{ fontSize: '14px' }} >Kindly enter your email address to receive the payment prompt </span> */}
                                    </Grid>

                                    {/*Mobile input*/}
                                    <Grid item>
                                        <div style={{ marginBottom: '20px', }}>
                                            <span style={{ fontSize: '12px', color: '#032541' }}>EMAIL ADDRESS.</span>
                                        </div>

                                        <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={email} onChange={(e) => setEmailAddress(e.target.value)} id="outlined-basic" label="Email Address" variant="outlined" />
                                    </Grid>

                                    {/*Amount Input*/}
                                    <Grid item>
                                        <div style={{ marginBottom: '10px' }}>
                                            <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                        </div>
                                        <TextField defaultValue={invoiceBalance} onChange={(e) => { setCardAmout(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                    </Grid>

                                    {/*Send prompt button*/}
                                    <Grid item mt={10} mb={9}>
                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                            <Grid item>
                                                {/* 
                                                {GenerteLink ? <>
                                                    <Button style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >CLICK HERE TO PAY</Button>

                                                </> : <>
                                                <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate  Link</Button>

                                                </>} */}

                                                {Cardloading ?
                                                    <>
                                                        <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                            endIcon={<SendIcon />}
                                                            loading={true}
                                                            loadingPosition="end"
                                                            variant="contained">
                                                            <span>Awaiting </span>
                                                        </LoadingButton>
                                                        <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                            <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                Generating Link: {counter}
                                                            </Typography>
                                                        </Box>
                                                    </>

                                                    :
                                                    <>
                                                        {/* {GenerateLinkStatus  ?  <div className="d-flex">
                                                        <div className="d-flex justify-content-space-between">
                                                            <Link to={`${dataLINK}`}>
                                                        
                                                        <Button  style={{ width: '300px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Open LINK</Button>
                                                        </Link>
                                                        <Button onClick={() => { handleCardPayment(invoiceNumber) }} className="ml-2" style={{ width: '300px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button>

                                                        </div>
                                                    </div> :
                                                <>
                                                <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button> </> */}
                                                        {/* } */}

                                                        <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button>

                                                    </>
                                                }

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </>
                                </>}

                               

                            </Grid>

                        </Grid> : activeButton == 'bankpaybill' ?
                            <>
                                <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                                    <Grid container direction={'column'} ml={1} spacing={3}>

                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay invoice via Bank Paybill</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item style={{ width: '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice No:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid>
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(invoiceBalance)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        {/*Mobile input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Bank </span>
                                            </div>

                                            {/* <SelectInput required inputStyle={{height : '55px', width : '500px'}} /> */}
                                            <TextField select label="Select Bank Name" id="demo-select-small" style={{ height: "55px", width: "500px", borderRadius: "1px", fontFamily: "Poppins", }}
                                                defaultValue={bankAccount}
                                                onChange={(e) => setBankAccount(e.target.value)}
                                                required>
                                                <MenuItem value={'522533'}>KCB</MenuItem>

                                            </TextField>
                                        </Grid>



                                        {/*Mobile input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                            </div>

                                            <PhoneInput required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={kcbphone} onChange={(kcbphone) => setkcbphone(kcbphone)} />
                                        </Grid>

                                        {/*Amount Input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={invoiceBalance} onChange={(e) => { setKcbAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>

                                        {/*Send prompt button*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    {kcbloading ?
                                                        <>
                                                            <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                                endIcon={<SendIcon />}
                                                                loading={true}
                                                                loadingPosition="end"
                                                                variant="contained">
                                                                <span>Awaiting Payment</span>
                                                            </LoadingButton>
                                                            <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                                <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                    Resend Prompt: {counter}
                                                                </Typography>
                                                            </Box>
                                                        </>

                                                        :
                                                        <>
                                                            <Button onClick={() => { handleKcbPush(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Send Prompt</Button>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </> : null
                }
                {/*Mpesa payment*/}
                <PaymentSent successOpen={successOpen} onClose={() => setSuccessOpen(false)} />
            </Grid>

        </div>
    )
}
